import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import CareerPage from '../templates/careers.js';
export const _frontmatter = {
  "title": "Careers"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const Button = makeShortcode("Button");
const Section = makeShortcode("Section");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const LottieFile1 = makeShortcode("LottieFile1");
const LottieFile2 = makeShortcode("LottieFile2");
const LottieFile3 = makeShortcode("LottieFile3");
const SplitSection = makeShortcode("SplitSection");
const BackgroundText = makeShortcode("BackgroundText");
const CareersForm = makeShortcode("CareersForm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = CareerPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Hero mdxType="Hero">
      <HeroImage mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAASPFkNdzpH//xAAZEAEBAAMBAAAAAAAAAAAAAAABAgADESP/2gAIAQEAAQUCHmQw662It+eVAP8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBJB/9oACAEDAQE/AVg//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABwQAAIABwAAAAAAAAAAAAAAAAARARASQUJRgf/aAAgBAQAGPwKw3CrRiJHZf//EABkQAQADAQEAAAAAAAAAAAAAAAEAEUEhUf/aAAgBAQABPyG2YrsxAYlaMeREIIJA4LsMQWp//9oADAMBAAIAAwAAABB7D//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QUrxZf//EABgRAAMBAQAAAAAAAAAAAAAAAAABEVFh/9oACAECAQE/EJSul6f/xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMUFhcYGh/9oACAEBAAE/EFCPRYchzUSJbtQcBG1Oj9giqgT0vfqB3KbPl8Slpc2f/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b7ee4335bedc91471874cb8880bd8f91/d3be9/careers.webp 480w", "/static/b7ee4335bedc91471874cb8880bd8f91/e46b2/careers.webp 960w", "/static/b7ee4335bedc91471874cb8880bd8f91/882b9/careers.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b7ee4335bedc91471874cb8880bd8f91/7cc5e/careers.jpg 480w", "/static/b7ee4335bedc91471874cb8880bd8f91/6a068/careers.jpg 960w", "/static/b7ee4335bedc91471874cb8880bd8f91/0f98f/careers.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b7ee4335bedc91471874cb8880bd8f91/0f98f/careers.jpg",
                "alt": "Solar Energy Careers",
                "title": "Solar Energy Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <h1>{`CAREERS`}</h1>
          <Button href="#inquire" mdxType="Button">Get Started Now</Button>
        </Container>
      </HeroContent></Hero>
    <Section mdxType="Section">
      <Container mdxType="Container">
        <h2>{`Why start your career with Solar Energy Partners?`}</h2>
        <p>{`Tired of the churn and burn nature of traditional door-to-door sales, of delayed or missing backend payments, toxic sales culture, and more? So are we.`}</p>
        <p>{`That's why we do things differently at SEP. We focus on building long-term careers with year-round opportunities, providing financial education for our team members, and creating a sustainable lifestyle.`}</p>
        <Row alignX='space-between' style={{
          textAlign: 'center'
        }} mdxType="Row">
          <Column sm={30} style={{
            paddingTop: 50
          }} mdxType="Column">
            <LottieFile1 mdxType="LottieFile1" />
            <h3>{`Long-Term Career`}</h3>
          </Column>
          <Column sm={30} style={{
            paddingTop: 50
          }} mdxType="Column">
            <LottieFile2 mdxType="LottieFile2" />
            <h3>{`Lifestyle`}</h3>
          </Column>
          <Column sm={30} style={{
            paddingTop: 50
          }} mdxType="Column">
            <LottieFile3 mdxType="LottieFile3" />
            <h3>{`Training & Education`}</h3>
          </Column>
        </Row>
      </Container>
    </Section>
    <SplitSection img='long-term-career' title='Long-term Career' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`We take the "summer" out of summer sales. If you are looking for a long-term career that offers desirable benefits and an independent lifestyle, then SEP may be right for you. The solar consultants that work with SEP enjoy top-tier onboarding, ongoing support, and sales systems engineered to help you produce results.`}</p>
      <p>{`95% of consultants that complete our onboarding process:`}</p>
      <ul>
        <li parentName="ul">{`Earn over six figures in their first year`}</li>
        <li parentName="ul">{`Look forward to getting out of bed in the morning`}</li>
      </ul>
      <Button href="#inquire" mdxType="Button">Fulfill Your Future</Button>
    </SplitSection>
    <SplitSection img='lifestyle-training1' title='Lifestyle' position='left' mdxType="SplitSection">
      <p>{`We understand that life is not about making money. It's about leveraging the money you've made to create a purposeful lifestyle that you enjoy. At SEP, you will get to create your own work schedule, work in any of our sales regions, and have the opportunity to participate in SEP-sponsored events and vacations all over the world.`}</p>
      <Button href="#inquire" mdxType="Button">Live The SEP Lifestyle</Button>
    </SplitSection>
    <SplitSection img='investment-education' title='Training and Education' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`The secret to the success of our solar consultants is in our three-week fast start program. We know that when our consultants are given the proper tools and resources to fully understand the solar industry and the needs of the consumer, they have the greatest chance of success.`}</p>
      <p>{`The three-week training boot camp includes:`}</p>
      <ul>
        <li parentName="ul">{`A dedicated SEP training coach`}</li>
        <li parentName="ul">{`Access to the SEP Solar Success Sales Library`}</li>
        <li parentName="ul">{`In-Person and Virtual Training`}</li>
        <li parentName="ul">{`Financial and Investment Education`}</li>
        <li parentName="ul">{`Access to our eight-part leadership development program`}</li>
      </ul>
      <Button href="#inquire" mdxType="Button">Start Your Training</Button>
    </SplitSection>
    <Section id='inquire' mdxType="Section">
      <Container style={{
        position: 'relative',
        marginTop: 65
      }} mdxType="Container">
        <h2 style={{
          position: 'relative',
          zIndex: 1
        }}>Chat With The Team</h2>
        <BackgroundText content='Start Your Career' style={{
          fontSize: '.8em',
          letterSpacing: '-.05em',
          marginTop: 30
        }} mdxType="BackgroundText" />
        <CareersForm mdxType="CareersForm" />
      </Container>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      